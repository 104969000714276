<template>
  <div v-if="IS_PENDING" class="csn-modal-background csn-global-loader">
    <Loader />
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import {
  Module,
  IS_PENDING,
  Digit,
  GLOBAL_LOADER,
  CURRENT_MODAL,
} from '@/constants'
import { setBodyOverflow, dest } from '@/helpers'
export default {
  name: GLOBAL_LOADER,
  components: {
    Loader: () => import('@/components/Loader'),
  },
  computed: {
    ...mapState(Module.MAIN, [IS_PENDING]),
    ...mapGetters({ modal: dest([Module.MODAL, CURRENT_MODAL]) }),
  },
  watch: {
    [IS_PENDING]: {
      immediate: true,
      async handler(isPending) {
        if (isPending) {
          await window.scrollTo(Digit.NOUGHT, Digit.NOUGHT)
          setBodyOverflow({ hidden: true })
          return
        }
        this.modal ? setBodyOverflow({ hidden: true }) : setBodyOverflow()
      },
    },
  },
  beforeCreate() {
    setBodyOverflow({ hidden: true })
  },
  beforeDestroy() {
    setBodyOverflow()
  },
}
</script>
